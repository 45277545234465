<template>
	<v-tab v-on="$listeners">
		{{ $t('team_manager.tabs_title.users') }}
		<v-icon>people</v-icon>
	</v-tab>
</template>

<script>
export default {
	name: 'UserManagementTab'
}
</script>
